import React, {useEffect} from "react";
import {AppStateActionTypes, AppStateContext} from "../shared/AppStateContext";
import { Box, Button, MenuItem, styled, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import ZipInput from '../shared/ZipInput';
import { getUTMParams, matchParentOrgName, useDebouncedEffect } from '@coverright/utils';
import { createStyles, makeStyles, withStyles } from '@mui/styles';
import { colors } from '@coverright/ui/themes';
import { CRPreloader } from '@coverright/ui/shared';
import { SelectInput } from '@coverright/ui/inputs';
import { environment } from '../environments/environment';
import BackButton from '../shared/BackButton';
import MedicarePlanCard from './MedicarePlanCard';
import { ExtraBenefit, Field, FilterItem, Plan, Sort } from '@coverright/data-access/types/medicare';
import { useMedicareFilterData, useMedicarePlans } from '@coverright/data-access/medicare';
import { useLogEvent } from '@coverright/shared/analytics';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontWeight: 500,
    },
    card: {
      padding: '24px 30px',
      maxWidth: 1000
    },
    disclosure: {
      fontSize: 20,
      fontWeight: 500,
      marginRight: 8,
      marginTop: 1,
    },
    underline: {
      borderBottom: '1px dashed #1C434F',
      cursor: 'pointer'
    },
    county: {
      cursor: 'pointer'
    },
    seeMoreButton: {
      '& .MuiButton-label': {
        textTransform: 'none',
        color: colors.custom.green.variant2
      }
    },
    hugeText: {
      fontSize: 32,
      fontWeight: 700,
    },
    bold: {
      fontWeight: 700,
    },
    thin: {
      fontWeight: 400,
    },
    select: {
      '& .MuiOutlinedInput-input': {
        fontSize: '12px',
        fontWeight: 500,
        padding: '12px 15px 10px!important',
        zIndex: 1,
        lineHeight: 'normal',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        marginTop: 4,
        height: 36,
      },
    },
    oopselect: {
      width: 'auto',
      minWidth: '95px'
    },
    paper: {
      '& li.MuiListItem-root': {
        fontSize: 12,
      }
    },
    logo: {
      width: 125,
      height: 24,
      maxWidth: 125,
      maxHeight: 24,
    }
  }),
);

function MedicarePlanList() {
  const classes = useStyles();
  const [appState] = React.useContext(AppStateContext);
  const [, dispatch] = React.useContext(AppStateContext);
  const [filterRequest, {data}] = useMedicareFilterData();
  const [plans, setPlans] = React.useState<Plan[]>([]);
  const [request, results] = useMedicarePlans();
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('md'));
  const [filters, setFilters] = React.useState<any>({zip: appState.zip, county: appState.county});
  const [planFilterData, setPlanFilterData] = React.useState<PlanFilterData>();
  const logEvent = useLogEvent();

  React.useEffect(() => {
    if (data) {
      setPlanFilterData({
        maxOutOfPocketRanges: data.maxOutOfPocketRanges,
        planTypes: data.planTypes,
      })
    }
  }, [data]);

  useDebouncedEffect(() => {
    if (!!filters.zip && !!filters.county)
      filterRequest({variables: {
        filter: {
          countyName: filters.county,
          zip: filters.zip
        }
        }})
    const planType = filters.planType ? filters.planType.replace('all', '') : '';
    const maxOutOfPocketRangeNames = filters.maxOutOfPocketRangeNames ? filters.maxOutOfPocketRangeNames.replace('all', '') : '';
      request({
        variables: {
          filter: {
            zip: filters.zip,
            countyName: filters.county,
            planTypes: planType ? [planType] : undefined,
            maxOutOfPocketRangeNames: maxOutOfPocketRangeNames ? [maxOutOfPocketRangeNames] : undefined,
            extraBenefits: [ExtraBenefit.DrugCoverage]
          },
          page: {
            page: 0,
            size: 3
          },
          sort: [
            { "field": Field.Rating,"direction":Sort.Desc },
            { "field": Field.MaxOutOfPocket, "direction": Sort.Asc},
            { "field": Field.Deductible, "direction": Sort.Asc},
            { "field": Field.Name, "direction": Sort.Asc}
          ]
        }
      })
  }, 300, [filters]);

  const filterString = React.useMemo(() => {
      return Object.keys(filters).map(k => k + '=' + filters[k]).join('&');
  }, [filters]);


  React.useEffect( () => {
    if(results.data) {
      setPlans(results.data.plans.data);
    }
  }, [results.data]);

  const onClick = React.useCallback((term: string, company?: string) => {
    logEvent('click', {
      element_title: 'See all plans now',
      element_id: term === 'ma_all_plans' ? 'partners-widget-ma-all-plans' : 'partners-widget-ma-single-plan',
      page: window.parent.location.href,
      page_canonical: window.parent.location.origin + window.parent.location.pathname
    }).then(() => {
      const host = window.parent.document.location.host;
      const isForbes = host === 'forbes.com' || host === 'www.forbes.com';
      if (isForbes) {
        const params = new URLSearchParams();
        params.append('zip', filters.zip);
        params.append('county', filters.county);
        params.append('mp', 'ma')

        if (matchParentOrgName(company)) {
          params.append('co', matchParentOrgName(company))
        }

        window.open(environment.dashboardUrl + 'forbes/basic-info?' + params.toString() + getUTMParams(term));
      } else {
        window.open(environment.dashboardUrl + 'standalone-flow/zip?' + filterString + getUTMParams(term));
      }
    })
  }, [filterString, filters]);

  return <>
    <Box flexDirection={'column'} alignItems={'center'}>
      <BackButton
        startIcon={<img src={environment.imagesUrl + "/img/arrow-left.svg"}/>}
        onClick={() => dispatch({type: AppStateActionTypes.BACK})}>
        Back
      </BackButton>
      <Typography color={'textPrimary'} className={'crw-medium crw-fs-22'}>Shop Medicare Advantage plans, 100% online.</Typography>
      <Typography color={'textSecondary'} className={'crw-fs-18 crw-mb-12'}>Filter your preferences below to refine your options.</Typography>
      <SeePlansButton variant={'rounded'}
                      sx={{fontSize: 18}}
                      id={'partners-widget-ma-all-plans'}
                      onClick={() => onClick('ma_all_plans')}
                      fullWidth>See all plans now</SeePlansButton>

      <div className={`crw-flex crw-mb-16 crw-mt-12 ${phone ? 'crw-flex-column' : ''}`}>
        <div className={'crw-flex crw-flex-align-center crw-mr-20'}>
          <Typography color={'textPrimary'} className={'crw-medium crw-fs-14 crw-mr-10'}>Zip</Typography>
          <ZipInput className={'crw-mb-0'} label={''} onChange={values => setFilters((prev: any) => ({...prev, ...values}))} zip={appState.zip} county={appState.county}  />
        </div>
        <div className={'crw-flex crw-flex-align-center crw-mr-20'}>
          <Typography color={'textPrimary'} className={`crw-medium crw-fs-14 crw-mr-10 ${phone ? 'crw-w-126 ' : ''}`}>Plan Type</Typography>
          <SelectInput label={''}
                       sx={{'& .MuiSelect-iconOutlined': {marginTop: '2px'}, '& div.MuiOutlinedInput-input': {m: '5px 2px 1px!important', p: '4px 15px 2px!important'}}}
                       className={`crw-mb-0 crw-w-95 ${classes.select}`}
                       paperClassName={classes.paper}
                       value={filters.planType || 'all'}
                       displayEmpty
                       onChange={(event) => {
                         setFilters((prev: any) => ({...prev, planType: event.target.value as string}))
                       }}
          >
            <MenuItem value={'all'}>All</MenuItem>
            {planFilterData?.planTypes.map(t => <MenuItem key={t.key} value={t.key}>{t.name}</MenuItem>)}
          </SelectInput>
        </div>
        <Box sx={{flex: 1}} className={'crw-flex crw-flex-align-center'}>
          <Typography sx={{whiteSpace: 'nowrap'}} color={'textPrimary'} className={'crw-medium crw-fs-14 crw-mr-10'}>Max out-of-pocket</Typography>
          <SelectInput label={''}
                       sx={{
                         '& .MuiSelect-iconOutlined': {marginTop: '2px'},
                         '& div.MuiOutlinedInput-input': {m: '5px 2px 1px!important', p: '4px 30px 2px 15px!important'},
                       }}
                       className={`crw-mb-0 ${classes.select} ${classes.oopselect}`}
                       paperClassName={classes.paper}
                       displayEmpty
                       value={filters.maxOutOfPocketRangeNames || 'all'}
                       onChange={(event) => {
                         setFilters((prev: any) => ({...prev, maxOutOfPocketRangeNames: event.target.value as string}))
                       }}
          >
            <MenuItem value={'all'}>All</MenuItem>
            {planFilterData?.maxOutOfPocketRanges.map(t => <MenuItem key={t.key} value={t.key}>{t.name}</MenuItem>)}
          </SelectInput>
        </Box>
      </div>

      <Box mt={'16px'}>

        {!results.loading && plans.map(plan => <MedicarePlanCard onClick={() => onClick('ma_single_plan', plan.parentOrgName || undefined)} plan={plan} key={plan.bidId} />)}
        {results.loading &&
          <Box textAlign={"center"} my={2}>
            <CRPreloader />
          </Box>
        }
      </Box>
      <div className={`crw-flex crw-flex-align-center crw-flex-space-between crw-mt-12 ${phone ? 'crw-flex-column' : ''}`}>
        <div className={`crw-flex crw-flex-align-center ${phone ? 'crw-mv-15' : ''}`}>
          <Typography color={'textPrimary'} className={'crw-fs-10 crw-pointer'}
                      onClick={() => window.open('https://coverright.com/coverright-terms-of-use/')}>Terms of Service</Typography>
          <Typography color={'textPrimary'} className={'crw-fs-10 crw-mh-8'}>|</Typography>
          <Typography color={'textPrimary'} className={'crw-fs-10 crw-pointer'}
                      onClick={() => window.open('https://coverright.com/privacy/')}>Privacy Policy</Typography>
        </div>
        <img src={environment.imagesUrl + '/img/Logo.svg'} className={classes.logo} />
      </div>
    </Box>
  </>
}

export default MedicarePlanList;


const SeePlansButton = withStyles({
  rounded: {
    color: 'white',
    backgroundColor: '#FF785A!important',
    boxShadow: 'none',
    borderRadius: 4,
    padding: '10px!important',
    '&:hover': {
      backgroundColor: '#FF551F!important',
      boxShadow: 'none',
    },
    '&:active': {
      backgroundColor: '#FF551F!important',
      boxShadow: 'none',
    },
  },
  label: {
    color: 'white',
    fontSize: 18,
    lineHeight: '15px',
    textTransform: 'none',
    fontWeight: 700,
  },
})(Button)

interface PlanFilterData {
  planTypes: FilterItem[],
  maxOutOfPocketRanges: FilterItem[],
}
